import PropTypes from 'prop-types';
import React from 'react';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { createConnector } from 'react-instantsearch-dom';
import { useTranslation } from 'react-i18next';
import { SearchIcon, CancelIcon } from './Icon';
import { SearchBar } from './SearchBar';
import { useIsMobile } from '../../../hooks/isMobile.hook';

const SearchBox = ({ defaultRefinement: value, refine: queryChange, searchInputPlaceholder }) => {
  const [translate] = useTranslation();

  const handleClearSearch = (e) => {
    const element = e.target;
    const parentElement = element.closest('div');
    const inputElement = parentElement.querySelector('input') || {};
    inputElement.value = '';
    queryChange('');
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      document.getElementById('all-results-link').click();
    }
  };

  const [isMobile] = useIsMobile();

  const examples = [
    'SEARCH.INPUT_PLACEHOLDER_EXAMPLE_A',
    'SEARCH.INPUT_PLACEHOLDER_EXAMPLE_B',
    'SEARCH.INPUT_PLACEHOLDER_EXAMPLE_C',
    'SEARCH.INPUT_PLACEHOLDER_EXAMPLE_D',
  ].map((s) => translate(s));

  const example = examples[Math.floor(Math.random() * examples.length)];

  return (
    <>
      <SearchIcon icon={faSearch} />
      <CancelIcon icon={faTimes} onClick={handleClearSearch} />
      <SearchBar
        id="search-articlepackages"
        type="search"
        placeholder={
          searchInputPlaceholder
          || (isMobile && translate('SEARCH.INPUT_PLACEHOLDER_MOBILE', { example }))
          || translate('SEARCH.INPUT_PLACEHOLDER')
        }
        autoComplete="off"
        defaultValue={value}
        onChange={(e) => queryChange(e.currentTarget.value)}
        onKeyPress={handleKeyPress}
      />
    </>
  );
};

const connectSearchBox = createConnector({
  displayName: 'SearchBoxWidget',
  getProvidedProps(props, searchState) {
    const currentRefinement = searchState.attributeForMyQuery || '';
    return { currentRefinement };
  },
  refine(props, searchState, nextRefinement) {
    return {
      ...searchState,
      attributeForMyQuery: nextRefinement,
    };
  },
  getSearchParameters(searchParameters, props, searchState) {
    return searchParameters.setQuery(searchState.attributeForMyQuery ?? props.defaultRefinement);
  },
  cleanUp(props, searchState) {
    const { attributeForMyQuery, ...nextSearchState } = searchState;
    return nextSearchState;
  },
});

SearchBox.propTypes = {
  defaultRefinement: PropTypes.string,
  refine: PropTypes.func.isRequired,
  searchInputPlaceholder: PropTypes.string,
};

SearchBox.defaultProps = {
  defaultRefinement: '',
  searchInputPlaceholder: undefined,
};

export default connectSearchBox(SearchBox);
