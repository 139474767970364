const Helpers = {
  isDevelopment: process.env.NODE_ENV === 'development',
  getCloudinaryUrl(imageUrl, params = ['f_auto', 'fl_lossy', 'q_auto:good']) {
    const { REACT_APP_CLOUDINARY_CLOUD_NAME } = process.env;
    if (!imageUrl || !REACT_APP_CLOUDINARY_CLOUD_NAME || imageUrl.startsWith('blob:')) return imageUrl;

    const dpr = typeof window !== 'undefined' ? window.devicePixelRatio.toFixed(1) : '1.0';
    if (dpr) params.push(`dpr_${dpr}`);

    const regex = new RegExp(`https?://res.cloudinary.com/${REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/(.*)`);
    if (regex.test(imageUrl)) {
      const publicId = imageUrl.match(regex)[1];
      return `https://res.cloudinary.com/${REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${params.join(',')}/${publicId}`;
    }

    return `https://res.cloudinary.com/${REACT_APP_CLOUDINARY_CLOUD_NAME}/image/fetch/${params.join(',')}/${imageUrl}`;
  },
  truncateString(str, maxChar) {
    if (str.length > maxChar) return `${str.slice(0, maxChar)}...`;
    return str;
  },
  createUniqueId() {
    return `_${Math.random().toString(36).substr(2, 9)}`;
  },
};

export default Helpers;
