import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Dinero from 'dinero.js';
import SearchWidget from './components/SearchWidget';
import ListWidget from './components/ListWidget';
import GlobalStyle from './GlobalStyle';
import './i18n';

Dinero.defaultCurrency = 'EUR';
Dinero.globalFormat = '$0,0.00';
Dinero.globalLocale = 'nl-BE';

const {
  searchInput, searchResults, onSearchResultsLoaded, searchResultsTheme, searchInputPlaceholder,
} = window.crowdsellingSearchOptions || {};

if (searchInput) {
  ReactDOM.render(
    <BrowserRouter>
      <GlobalStyle />
      <SearchWidget searchInputPlaceholder={searchInputPlaceholder} />
    </BrowserRouter>,
    document.getElementById(searchInput),
  );
}

if (searchResults) {
  ReactDOM.render(
    <BrowserRouter>
      <GlobalStyle />
      <ListWidget afterLoadResults={onSearchResultsLoaded} searchResultsTheme={searchResultsTheme} />
    </BrowserRouter>,
    document.getElementById(searchResults),
  );
}
