import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = (key) => {
  const location = useLocation();
  const [queryParam, setQueryParam] = useState();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const value = params.get(key);
    setQueryParam(value);
  }, [location, key]);
  return queryParam;
};
