import React from 'react';
import { connectSortBy } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  SortWrapper, SortOptionsWrapper, SortLabel, SortSelect,
} from './Sort';

const SortHits = ({
  items, refine, createURL, setDefaultRefinement, defaultRefinement, searchResultsTheme, query,
}) => {
  const [translate] = useTranslation();
  const isWhite = searchResultsTheme === 'white';

  return (
    <SortWrapper query={query}>
      {query && (
        <button
          type="button"
          className="button small white w-button"
          onClick={() => setDefaultRefinement('')}
        >
          {translate('RESULTS.FILTER.CLEAR_FILTER')}
        </button>
      )}
      <SortOptionsWrapper>
        <SortLabel className={isWhite ? ' text-white' : ''}>{translate('RESULTS.FILTER.SORT_BY')}</SortLabel>
        {' '}
        <SortSelect
          className="w-select"
          onChange={(e) => {
            e.preventDefault();
            refine(e.target.value);
          }}
          defaultValue={defaultRefinement}
        >
          {items.map((item) => (
            <option
              key={item.value}
              value={item.value}
              href={createURL(item.value)}
            >
              {item.label}
            </option>
          ))}
        </SortSelect>
      </SortOptionsWrapper>
    </SortWrapper>
  );
};

SortHits.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  refine: PropTypes.func,
  createURL: PropTypes.func,
  setDefaultRefinement: PropTypes.func.isRequired,
  defaultRefinement: PropTypes.string,
  searchResultsTheme: PropTypes.string,
  query: PropTypes.string,
};

SortHits.defaultProps = {
  items: [],
  refine: undefined,
  createURL: undefined,
  defaultRefinement: '',
  searchResultsTheme: undefined,
  query: '',
};

export default connectSortBy(SortHits);
