import styled from 'styled-components';

export const SortWrapper = styled.div`
  display: flex;
  justify-content: ${({ query }) => (query ? 'space-between' : 'flex-end')};
  padding: 0.5rem;

  @media screen and (max-width: 425px) {
    flex-direction: column-reverse;
    justify-content: end;

    & > * {
      margin-bottom: 1rem;
    }
    
    *:first-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 0.5rem 0;
  }
`;

export const SortOptionsWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1.5fr;
  align-items: center;
  outline: none;
`;

export const SortLabel = styled.label`
  text-align: left;
  margin: 0 0 0 15px;
`;

export const SortSelect = styled.select`
  background-color: #fff;
  background-image: none;
  box-shadow: none;
  border-radius: 4px;
  padding: 0 0.25rem;
  margin: 0;
`;
