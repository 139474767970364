import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --main-font-color: #6078ea;
    --secondary-font-color: #30364d;
  }

  .loading-icon-wrapper {
    margin-top: 2rem;
    min-height: 3rem;
  }

  .fade-in {
    -webkit-animation: fade-in 0.65s;
    animation: fade-in 0.65s;
  }

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  };

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    
    100% {
      opacity: 1;
    }
  };
`;

export default GlobalStyle;
