import styled from 'styled-components';

export const SearchBar = styled.input`
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 0.75rem 3rem;
  border:1px solid #ccc;
  outline: none;
  border-radius: 4px;
  color: #30364d !important;

  &:focus {
    + ul {
      display: block;
    }
  }
`;
