import styled from 'styled-components';

export const Dropdown = styled.ul`
    display: none;
    width: 100%;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,0.2);
    padding: 0;
    margin: 0;
    z-index: 1000;
    list-style-type: none;
    border-radius: 4px;
    
    &:hover {
      display: block;
    }
  `;

export const DropdownItem = styled.a`
    display: flex;
    padding: 1rem;
  `;

export const LinkItem = styled.a`
    color: var(--main-font-color);
  `;

export const ItemImage = styled.img`
    object-fit: contain;
    width: 75px;
  `;

export const ItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  `;

export const Text = styled.p`
    font-size: 0.9rem;
    margin: 0 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

export const LinkLabel = styled.p`
    margin: 0;
    font-weight: 900;
    font-size: 0.9rem;
  `;
