import React, { useCallback, useEffect } from 'react';
import { connectInfiniteHits, connectStateResults } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dinero from 'dinero.js';
import { useIsMobile } from '../../../hooks/isMobile.hook';
import { useIntersect } from '../../../hooks/intersect.hook';
import Helpers from '../../../Helpers';
import ConnectedSortBy from '../SortHits';
import SearchWidget from '../../SearchWidget';
import LoadingIcon from '../../Utils/LoadingIcon';

const ListHits = ({
  hits = [], refineNext, hasMore, searchResults, setDefaultRefinement, sortByParam, searchResultsTheme, searching,
}) => {
  const [translate] = useTranslation();
  const [isMobile] = useIsMobile();

  const onSentinelIntersection = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && hasMore) {
          refineNext();
        }
      });
    },
    [refineNext, hasMore],
  );

  const [ref] = useIntersect({ callback: onSentinelIntersection });

  const SORT_INDEXES = {
    most_popular: {
      value: 'articlepackages',
      label: translate('RESULTS.FILTER.MOST_POPULAR'),
    },
    highest_profit: {
      value: 'articlepackages_highest_profit_margin_desc',
      label: translate('RESULTS.FILTER.HIGHEST_PROFIT'),
    },
    highest_rating: {
      value: 'articlepackages_highest_rating_desc',
      label: translate('RESULTS.FILTER.HIGHEST_RATING'),
    },
  };

  const { query = '' } = searchResults || {};
  const hasResults = !!hits.length;

  useEffect(() => {
    const titleElement = document.getElementById('js-replace-search-title');
    titleElement.setAttribute('data-default-title', titleElement.innerText);
  }, []);

  useEffect(() => {
    if (searchResults) {
      const titleElement = document.getElementById('js-replace-search-title');
      if (hasResults && !!query) {
        titleElement.innerText = translate('RESULTS.SALES_FOR', { query });
      } else if (hasResults && !query) {
        titleElement.innerText = titleElement.getAttribute('data-default-title');
      } else {
        titleElement.innerText = translate('RESULTS.NO_SALES', { query });
      }
    }
  }, [hasResults, query, searchResults, translate]);

  const src = 'https://assets.website-files.com/5f3254f871be6f2835d7baba/5f3254f99b460a44c03f30ab_star-filled-yellow.svg';

  return (
    <>
      <div style={{ padding: 8, marginBottom: 25 }}>
        {isMobile && (
          <div style={{ marginBottom: 15 }}>
            <SearchWidget />
          </div>
        )}
        {hasResults && (
        <ConnectedSortBy
          query={query}
          defaultRefinement={(SORT_INDEXES[sortByParam] || SORT_INDEXES.most_popular).value}
          setDefaultRefinement={setDefaultRefinement}
          items={Object.values(SORT_INDEXES)}
          searchResultsTheme={searchResultsTheme}
        />
        )}
      </div>
      <div className="w-dyn-list">
        <div role="list" className="posts-3-cols w-dyn-list" style={{ gridColumnGap: 16, gridRowGap: 16 }}>
          {hits.map((a) => (
            <div className="w-dyn-item fade-in" key={a.objectID}>
              <a
                href={a.url}
                className="post-card white w-inline-block"
              >
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={Helpers.getCloudinaryUrl(a.imageUrl, ['w_300',
                      'c_pad',
                      'b_white',
                      'ar_4:3',
                      'f_auto',
                      'fl_lossy',
                    ])}
                    className="dropdown-item_image"
                    loading="lazy"
                    alt={Helpers.truncateString(a.name, 16)}
                  />
                </div>
                <div className="post-card-info">
                  <div className="post-card-category">{(a.partner || {}).name}</div>
                  <h3 className="post-card-heading heading-5">{a.name}</h3>
                </div>
                <div className="post-card-bottom">
                  <div className="w-embed">
                    {translate('RESULTS.PROFIT_AMOUNT', {
                      amount: Dinero({ amount: a.highestProfitMargin || 0 }).toFormat(),
                    })}
                  </div>
                </div>
                {a.ratingCount && (
                  <div className="post-card-bottom">
                    <div className="review-stars">
                      {[...Array(Math.floor(a.averageRating || 0))].map(() => (
                        <img
                          key={Helpers.createUniqueId()}
                          src={src}
                          className="review-v1-star"
                          alt="Star icon"
                        />
                      ))}
                    </div>
                    &nbsp;
                    <div className="flex-child-align-center w-embed">
                      {translate('RESULTS.REVIEWS_COUNT', { count: a.ratingCount })}
                    </div>
                  </div>
                )}
              </a>
            </div>
          ))}
        </div>
        <div ref={ref} />
        <div className="w-pagination-wrapper loading-icon-wrapper">
          {searching && (<LoadingIcon />)}
        </div>
      </div>
    </>
  );
};

ListHits.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.shape({})),
  refineNext: PropTypes.func,
  hasMore: PropTypes.bool,
  searchResults: PropTypes.shape({
    query: PropTypes.string,
  }),
  setDefaultRefinement: PropTypes.func.isRequired,
  sortByParam: PropTypes.string,
  searchResultsTheme: PropTypes.string,
  searching: PropTypes.bool,
};

ListHits.defaultProps = {
  hits: [],
  refineNext: undefined,
  hasMore: false,
  searchResults: {},
  sortByParam: '',
  searchResultsTheme: undefined,
  searching: false,
};

export default connectInfiniteHits(connectStateResults(ListHits));
