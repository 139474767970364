import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = styled(FontAwesomeIcon)`
  min-width: 2.5rem;
  z-index: 1;
  position: absolute;
  
  path {
    fill: var(--secondary-font-color) !important;
  }
`;

export const SearchIcon = styled(Icon)`
  top: 0.9rem;
  left: 0.5rem;
`;

export const CancelIcon = styled(Icon)`
  top: 0.9rem;
  right: 0.5rem;
  cursor: pointer;
`;
