import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Helpers from './Helpers';

import translationEN from './locales/en/translation.json';
import translationNL from './locales/nl/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  nl: {
    translation: translationNL,
  },
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: {
      en: ['nl'],
      default: ['nl'],
    },
    debug: Helpers.isDevelopment,
    react: {
      useSuspense: false,
    },
    interpolation: {
      prefix: '%',
      suffix: '%',
      escapeValue: false,
    },
  });

export default i18n;
