import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Helpers from '../../../Helpers';
import {
  Dropdown, DropdownItem, LinkItem, ItemImage, ItemInfo, Text, LinkLabel,
} from './Dropdown';

const { REACT_APP_ARTICLE_PACKAGES_PAGE_URL } = process.env;

const SearchHits = ({ hits = [], searchResults }) => {
  const [translate] = useTranslation();
  const { query = '' } = searchResults || {};
  const hasResults = !!hits.length;

  const switchMessageRender = () => {
    switch (true) {
      case hasResults && !!query:
        return translate('SEARCH.ALL_RESULTS_QUERY', { query });

      case hasResults && !query:
        return translate('SEARCH.ALL_RESULTS');

      default:
        return translate('SEARCH.NO_RESULTS', { query });
    }
  };

  return (
    <Dropdown className="fade-in">
      {hits.map((hit) => (
        <li key={hit.objectID}>
          <DropdownItem
            className="nav-dropdown-link w-dropdown-link"
            href={hit.url}
          >
            <ItemImage
              src={Helpers.getCloudinaryUrl(hit.imageUrl, ['w_75',
                'c_pad',
                'b_white',
                'ar_4:3',
                'f_auto',
                'fl_lossy',
              ])}
              alt={Helpers.truncateString(hit.name, 16)}
            />
            <ItemInfo>
              <Text style={{ fontWeight: 900 }}>{hit.name}</Text>
              <Text>{(hit.partner || {}).name}</Text>
            </ItemInfo>
          </DropdownItem>
        </li>
      ))}
      <li>
        <LinkItem
          id="all-results-link"
          className="nav-dropdown-link w-dropdown-link"
          href={hasResults ? `${REACT_APP_ARTICLE_PACKAGES_PAGE_URL}${query ? `?search=${query}` : ''}` : null}
        >
          <LinkLabel>
            {switchMessageRender()}
            {' '}
            {hasResults && (<FontAwesomeIcon icon={faArrowRight} />)}
          </LinkLabel>
        </LinkItem>
      </li>
    </Dropdown>
  );
};

SearchHits.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.shape({})),
  searchResults: PropTypes.shape({
    query: PropTypes.string,
  }),
};

SearchHits.defaultProps = {
  hits: [],
  searchResults: {},
};

export default connectHits(connectStateResults(SearchHits));
