import React, { useState, useEffect } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import ConnectedHits from './ListHits';
import { useQueryParams } from '../../hooks/queryParams.hook';

const { REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY } = process.env;

const searchClient = algoliasearch(REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY);

const ArticlePackageListWidget = ({
  searchResultsTheme, afterLoadResults,
}) => {
  const searchParam = useQueryParams('search');
  const sortByParam = useQueryParams('sortBy');
  const [defaultRefinement, setDefaultRefinement] = useState('');

  useEffect(() => {
    if (searchParam) setDefaultRefinement(searchParam);
  }, [searchParam]);

  useEffect(() => {
    if (afterLoadResults) afterLoadResults();
  }, [afterLoadResults]);

  return (
    <InstantSearch searchClient={searchClient} indexName="articlepackages">
      <Configure
        query={defaultRefinement}
        hitsPerPage={18}
      />
      <ConnectedHits
        setDefaultRefinement={setDefaultRefinement}
        sortByParam={sortByParam}
        searchResultsTheme={searchResultsTheme}
      />
    </InstantSearch>
  );
};

ArticlePackageListWidget.propTypes = {
  searchResultsTheme: PropTypes.string,
  afterLoadResults: PropTypes.func,
};

ArticlePackageListWidget.defaultProps = {
  searchResultsTheme: undefined,
  afterLoadResults: undefined,
};

export default ArticlePackageListWidget;
