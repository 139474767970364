import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import ConnectedSearchBox from './SearchBox';
import ConnectedHits from './SearchHits';
import { useQueryParams } from '../../hooks/queryParams.hook';

const { REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY } = process.env;

const searchClient = algoliasearch(REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY);

const SearchWidget = ({ searchInputPlaceholder }) => {
  const searchParam = useQueryParams('search');
  const [defaultRefinement, setDefaultRefinement] = useState('');

  useEffect(() => {
    if (searchParam) setDefaultRefinement(searchParam);
  }, [searchParam]);

  return (
    <InstantSearch searchClient={searchClient} indexName="articlepackages">
      <div style={{ position: 'relative' }}>
        <Configure
          hitsPerPage={3}
        />
        <ConnectedSearchBox defaultRefinement={defaultRefinement} searchInputPlaceholder={searchInputPlaceholder} />
        <ConnectedHits />
      </div>
    </InstantSearch>
  );
};

SearchWidget.propTypes = {
  searchInputPlaceholder: PropTypes.string,
};

SearchWidget.defaultProps = {
  searchInputPlaceholder: undefined,
};

export default SearchWidget;
