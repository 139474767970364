import { useEffect, useState, useRef } from 'react';

export const useIntersect = ({ callback, options }) => {
  const [node, setNode] = useState(null);
  const observer = useRef(null);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(callback, options);
    const { current: currentObserver } = observer;

    if (node) currentObserver.observe(node);

    return () => currentObserver.disconnect();
  }, [node, callback, options]);

  return [setNode];
};
